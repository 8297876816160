import React from "react"

import Layout from "../../components/layout/layout-st"
import Seo from "../../components/seo"

import * as style from '../../components/content/content.module.scss'

import Panel from "../../components/panels/panel"


const STReviews = () => (
  <Layout>
    
    <Seo title="Reviews" bodyClass='st'/>

    
    
    <div className="column column--30 alignright">
      <div className="sticky">
        <h1 className={style.titleLowercase}>Reviews</h1>
        <p>Yes, She’s Specyal! See what Industry Pros and the Press have to say about T’s vibe.</p>
      </div>
    </div>
    <div className="column column--70">

    <Panel 
        header={`Specyal T pioneered nostalgia for her boundary-breaking boom-bap RnB pop track, Double Take”`}
        content={`<a href="https://www.anrfactory.com/specyal-t-pioneered-nostalgia-for-her-boundary-breaking-boom-bap-rnb-pop-track-double-take/" target="_blank" style="color:#662d91">Read the review &rarr;</a>`}
        footer={`A&R Factory`}      
        />

    <Panel 
        header={`Specyal T Invites You To Explore Life And Love’s Infinite Possibilities In, “Foresight”`}
        content={`<a href="https://www.buzz-music.com/post/specyal-t-invites-you-to-explore-life-and-love-s-infinite-possibilities-in-foresight" target="_blank" style="color:#662d91">Read the review &rarr;</a>`}
        footer={`BuzzMusic`}      
        />

    <Panel 
        header={`The tone of her delivery [on Double Take] is breathy, slinky, and evocatively seductive but laced with just a hint of danger. A sweetly lulling mix of clubby R&B and sweetly electro-dipped hip- hop.`}
        // content={`Her new single Box Food has massive swagger and Specyal T’s vocals strike a fiery contrast with the strong hook. Her technique never sounds overwrought, instead, it embodies her songwriting in a dramatic way.`}
        footer={`Ital Massive`}      
        />
        
    <Panel 
        header={`Ones To Watch 2020: Canada’s Very Own Specyal T Talks New Music`}
        content={`Her new single Box Food has massive swagger and Specyal T’s vocals strike a fiery contrast with the strong hook. Her technique never sounds overwrought, instead, it embodies her songwriting in a dramatic way.`}
        footer={`Urban Music Awards`}      
        />

    <Panel 
        header={`Experience Unique R&B Through Specyal T’s Dynamic Album ‘A Specyal Remedy’ `}
        content={`Specyal T vocalizes similar to Missy Elliot with power and poise.Her vocals open full of breath and passion sang in an intriguing low octave. ‘Box Food’ is a groove we can get down to every day! With an incredibly unique style emphasized on ‘Three,’ she takes another beautiful love song to an authentic level. ‘A Specyal Remedy’ was a captivating piece. From Specyal T’s unique vocal delivery to her array of atmospheres, the album is not one to miss!`}
        footer={`BuzzMusic`}      
        />


    <Panel 
        header={`Take a listen to "Girl Friday" by Specyal T on Juss Russ Radio. `}
        content={`Specyal T is an emerging Hip-Hop artist that has been doing her thing and making waves online for quite some time now. Up to this point, she has relied heavily on the use of free streaming platforms such as SoundCloud to serve as the bridge connecting her sound to the world. She recently released a new EP entitled, "Girl Friday" and has been receiving a lot of love via social media since then. This new EP is a perfect snapshot of where Specyal T is in regards to her life and career right now. She uses this short body of work to fill in her fans on some of her blessings, shortcomings, tragedies, and triumphs all in one concise package. She is a rather versatile artist who always finds ways to step outside of her comfort zone and provide her fans with a fresh vibe or perspective to take in. It has been her mystique and songwriting that have ultimately allowed her to garner such mass attention early on, and if she stays consistent…who knows how far she could go. Do yourself the favor and tap in to her new project and be sure to stream some of her other tunes on SoundCloud. Simply give her a follow to do so!`}
        footer={`Juss Russ Radio`}      
        />

      <Panel 
        header={`Specyal T has made considerably strides forward in the evolution of her own music. `}
        content={`The Tionne EP has tremendous emotional content to it, and the EP's first single, Side Effects, immediately catches long-time listeners up. The echoing, booming sound of the chorus will ensure that listeners are firmly on the edges of their seats. The blending of performers like Danny Brown and Tyler The Creator makes for an interesting introduction to this EP.`}
        footer={`NeuFutur Magazine`}      
        />

      <Panel 
        header={`Specyal T's lyrics; hints of Missy Elliott and Eve can be discerned here.`}
        content={`Specyal T's C.A.K.E. EP, a five-track effort that will provide listeners with a full array of styles, influences, and sounds upon which to familiarize themselves with this inimitable performer. Specyal T's lyrics; hints of Missy Elliott and Eve can be discerned here.
        Just A Thot is a blend of R&B and dance, with Specyal T's vocals utilizing a hard-hitting and rapid-fire flow that does as much to the overall sound of the track as the dense and bushy instrumentation. This chorus deserve ample airplay on radio stations.She ends this EP with When You Knew. This cooler track bursts into flame as Specyal T moves into the chorus. The inclusion of this laid-back jazz-infused sound showcases that this performer can continue to grow and change even at the final strains of a release.`}
        footer={`NeuFutur Magazine`}      
        />
      <Panel 
        header={`Refreshing!`}
        content={` Cool old school vibes manifest in this awesome track. Great beat, wicked flow.`}
        footer={`Alex Csethe, iTunes`}      
        />

      <Panel 
        header={`Dose of Distinction 2 shows considerable progression from Specyal T's debut release. `}
        content={`The 12 tracks on the title showcase a wide array of different styles, genres, and overall approaches taken by this stellar performer.`}
        footer={`James Mcquiston, Neufutur Magazine`}      
        />
      <Panel 
        header={`Well Worth The Wait!`}
        content={` Amazing album. Loving "Paper Cuts". So worth the wait!`}
        footer={`Alex Csethe, iTunes`}      
        />
      <Panel 
        header={`Deep Impact Heartfelt lyrics really hit home.`}
        content={` Great flow here for sure I'm digging DOD2.`}
        footer={`Toronto Indie Guy, iTunes`}      
        />
      <Panel 
        header={`This woman has a deep voice when she raps`}
        content={`Her influences are her husband and her dad. All of these songs where recorded in her own home studio. Listen to Lauryn Hill.`}
        footer={`Katimavik - KCR Music CJLY 93.5 FM
        Nelson BC, Canada`}      
        />
      <Panel 
        header={`Sweat It Up! (Urban Fusion Remix)`}
        content={`Great track I will be adding this to one of my many different playlists.`}
        footer={`Matt Fossey - Wired 96.3
        Saskatoon SK, Canada`}      
        />
      <Panel 
        header={`Push Play`}
        content={`Very trippy beat but very catchy. Also think one would have to appreciate music in its essence to really appreciate this song`}
        footer={`Al Rolle - 2nd Floor Lounge
        Peterborough ON, Canada`}      
        />
      <Panel 
        header={`Paralyzed`}
        content={`Excellent Hip-hop Pop that has an awesome beat. Looking forward to some upcoming remixes...`}
        footer={`Tim Patterson BPM Productions
        Woodbridge ON, Canada`}      
        />
      <Panel 
        header={`Salt & Pepper`}
        content={`You’ve got that really old school, Etta James, thing going on. Not too many currently that can rock that.`}
        footer={`Arun Moorthy - Slept Productions
        Mississauga ON, Canada`}      
        />
      <Panel 
        header={`Caught Ya Lookin"`}
        content={`Great vocals and beats here. Should do well!`}
        footer={`Kenn Burrola - Liquid Rhythms Mixshow, KRCL 90.9 FM
        West Jordan, United States`}      
        />
      <Panel 
        header={`Sweat It Up! (Urban Fusion Remix)`}
        content={`This girl has talent!`}
        footer={`Wayne Murray - Avid Productions / River City Pub
        Revelstoke BC, Canada`}      
        />
      <Panel 
        header={`Caught Ya Lookin`}
        content={`UN bUON LAVORO E UN BUON PRODOTTO" (A good job and a good product)`}
        footer={`Enzo Persueder - Freesound, Radio Domani
        Modena, Italy`}      
        />
      <Panel 
        header={`Lie To Me" ft. Specyal T`}
        content={`Nice soulful track`}
        footer={`Anthony Gelo - SideTracks / Bar None
        Ozone Park, United States`}      
        />
      </div>

  </Layout>
)

export default STReviews
