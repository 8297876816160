// import React, { ReactNode } from 'react';
import React from "react"
import PropTypes from "prop-types"
import * as style from './panel.module.scss'




const Panel = ({idname, header, content, innerpanel, footer}) => (
    <div className={style.panel} id={idname} >
      
        

        {header && (
        <div className={style.panel__header}>
            {header}
        </div>
        )}

        {content && (
        <div className={style.panel__content} dangerouslySetInnerHTML={{ __html: content }}>
        </div>
        )}

        {innerpanel && (
        <div className={style.panel__innerpanel} dangerouslySetInnerHTML={{ __html: innerpanel }}>
        </div>
        )}

        {footer && (
        <div className={style.panel__footer}>
            {footer}
        </div>
        )}

    </div>
)






Panel.defaultProps = {
    header: ``,
    content: ``,
    innerpanel: ``,
    footer: ``,
    idname: ``,
  }
  
  Panel.propTypes = {
    header: PropTypes.string,
    content: PropTypes.any,
    innerpanel: PropTypes.any,
    footer: PropTypes.string,
    idname: PropTypes.string,
  }


export default Panel
